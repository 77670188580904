import React from 'react';
import { Flex, Text, Icon, Box } from '@chakra-ui/react';
import { CgHome } from 'react-icons/cg';
import { FaHockeyPuck } from 'react-icons/fa';
import { TbMessageCircle2 } from 'react-icons/tb';
import { IoNotificationsOutline } from 'react-icons/io5';
import { RxPerson } from 'react-icons/rx';
import { AiOutlineSetting } from 'react-icons/ai';
import { useRouter } from 'next/router';
import { useIntercom } from 'react-use-intercom';
import { HiOutlineWrenchScrewdriver, HiOutlineShieldCheck } from 'react-icons/hi2';
const getIcon = (icon: string, isActive: boolean) => {
  const iconBg = process.env.NEXT_PUBLIC_PARTNER_CODE === 'MTNNG' ? '#FFFAED' : '#AAE8FF';
  switch (icon) {
    case 'Home':
      return <Box display="flex" mr={!isActive ? '10px' : '0px'} bg={!isActive ? iconBg : ''} color={!isActive ? 'icon' : 'white'} p={!isActive ? '8px' : '0px'} borderRadius="8px" w="32px">
          <Icon as={CgHome} boxSize="18px" />
        </Box>;
      break;
    case 'Transactions':
      return <Box display="flex" mr={!isActive ? '10px' : '0px'} bg={!isActive ? iconBg : ''} color={!isActive ? 'icon' : 'white'} p={!isActive ? '8px' : '0px'} borderRadius="8px" w="32px">
          <Icon as={FaHockeyPuck} boxSize="15px" />
        </Box>;
      break;
    case 'Message':
      return <Box display="flex" mr={!isActive ? '10px' : '0px'} bg={!isActive ? iconBg : ''} color={!isActive ? 'icon' : 'white'} p={!isActive ? '8px' : '0px'} borderRadius="8px" w="32px">
          <Icon as={TbMessageCircle2} boxSize="15px" mr="22px" />
        </Box>;
      break;
    case 'Notifications':
      return <Box display="flex" mr={!isActive ? '10px' : '0px'} bg={!isActive ? iconBg : ''} color={!isActive ? 'icon' : 'white'} p={!isActive ? '8px' : '0px'} borderRadius="8px" w="32px">
          <Icon as={IoNotificationsOutline} boxSize="15px" mr="22px" />
        </Box>;
      break;
    case 'Profile':
      return <Box display="flex" mr={!isActive ? '10px' : '0px'} bg={!isActive ? iconBg : ''} color={!isActive ? 'icon' : 'white'} p={!isActive ? '8px' : '0px'} borderRadius="8px" w="32px">
          <Icon as={RxPerson} boxSize="15px" mr="22px" />
        </Box>;
      break;
    case 'Settings':
      return <Box display="flex" mr={!isActive ? '10px' : '0px'} bg={!isActive ? iconBg : ''} color={!isActive ? 'icon' : 'white'} p={!isActive ? '8px' : '0px'} borderRadius="8px" w="32px">
          <Icon as={AiOutlineSetting} boxSize="15px" mr="22px" />
        </Box>;
      break;
    case 'Request Fix':
      return <Box display="flex" mr={!isActive ? '10px' : '0px'} bg={!isActive ? iconBg : ''} color={!isActive ? 'icon' : 'white'} p={!isActive ? '8px' : '0px'} borderRadius="8px" w="32px">
          <Icon as={HiOutlineWrenchScrewdriver} boxSize="15px" mr="22px" />
        </Box>;
      break;
    case 'Device Protection':
      return <Box display="flex" mr={!isActive ? '10px' : '0px'} bg={!isActive ? iconBg : ''} color={!isActive ? 'icon' : 'white'} p={!isActive ? '8px' : '0px'} borderRadius="8px" w="32px">
          <Icon as={HiOutlineShieldCheck} boxSize="15px" mr="22px" />
        </Box>;
      break;
    default:
      return <Icon />;
      break;
  }
};
interface LinkProps {
  label: string;
  icon: string;
  url: string;
  link: string;
  size: string;
}
const NavLink = (props: {
  link: LinkProps;
}) => {
  const {
    label,
    icon,
    url
  } = props.link;
  const router = useRouter();
  const {
    show
  } = useIntercom();
  const isActive = router.pathname === url;

  // #FFAA2B
  const bg = process.env.NEXT_PUBLIC_PARTNER_CODE === 'MTNNG' ? '#000' : '#2DC7FF';
  const activeStyle = isActive ? {
    bg: bg,
    color: '#210124',
    fontWeight: 'bold',
    borderLeftRadius: '8px',
    padding: '10px 9px'
  } : {
    bg: 'transparent',
    color: 'white'
  };
  const handleNav = () => {
    router.push(url);
  };
  return <Box {...activeStyle} onClick={label === 'Message' ? show : handleNav} _hover={{
    cursor: 'pointer'
  }} data-sentry-element="Box" data-sentry-component="NavLink" data-sentry-source-file="nav-link.tsx">
      <Flex alignItems="center" data-sentry-element="Flex" data-sentry-source-file="nav-link.tsx">
        {getIcon(icon, isActive)}
        <Text fontSize="15px" lineHeight="18px" fontWeight="600" color={process.env.NEXT_PUBLIC_PARTNER_CODE === 'MTNNG' && isActive ? '#fff' : 'title'} data-sentry-element="Text" data-sentry-source-file="nav-link.tsx">
          {label}
        </Text>
      </Flex>
    </Box>;
};
export default NavLink;