import { Box, Divider, Flex, Image } from '@chakra-ui/react';
import { getLogoByPartner, getTitleByPartner } from '../../utils/partnerTheme';
import Link from 'next/link';
import NavLink from './nav-link';
import React from 'react';
import SecondaryNavMenu from './secondary-nav';
interface Link {
  link: string;
  label: string;
  icon: string;
  url: string;
  size: string;
}
interface LinkProps {
  label: string;
  icon: string;
  url: string;
  link: string;
  size: string;
}
const SideNav = ({
  links,
  hideSecNav
}: {
  links: Array<Link>;
  hideSecNav?: boolean;
}) => {
  return <Box w={{
    base: '100%',
    md: '280px'
  }} bg="primary" height="100%" pos="fixed" right={0} left={0} top={0} data-sentry-element="Box" data-sentry-component="SideNav" data-sentry-source-file="side-nav.tsx">
      <Box pl={7} py={12} data-sentry-element="Box" data-sentry-source-file="side-nav.tsx">
        <Link href="/" data-sentry-element="Link" data-sentry-source-file="side-nav.tsx">
          <Flex w="100%" justifyContent={{
          base: 'center',
          sm: 'left'
        }} data-sentry-element="Flex" data-sentry-source-file="side-nav.tsx">
            <Image maxW={150} src={getLogoByPartner(true).src} alt={`${getTitleByPartner()} Logo`} data-sentry-element="Image" data-sentry-source-file="side-nav.tsx" />
          </Flex>
        </Link>

        <Flex flexDirection="column" gap={8} aria-label="Main navigation" pt={20} pb={10} data-sentry-element="Flex" data-sentry-source-file="side-nav.tsx">
          {links.map((link: object, i: number) => <NavLink key={i} link={link as LinkProps} />)}
        </Flex>
        {!hideSecNav && <>
            <Divider />
            <Box display="flex" bottom={0} zIndex={1000} marginBottom="30px" position="relative" alignItems="center">
              <SecondaryNavMenu />
            </Box>
          </>}
      </Box>
    </Box>;
};
export default SideNav;