import React, { useState, useEffect } from 'react';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, Text, ModalContentProps, TextProps, ModalHeaderProps, ModalProps } from '@chakra-ui/react';
interface Props extends ModalContentProps {
  children: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
  header?: string;
  closeBtn: boolean;
  headerTextPos?: TextProps;
  headerProps?: ModalHeaderProps;
  modalProps?: ModalProps;
}
const ModalComponent: React.FC<Props> = ({
  children,
  isOpen,
  onClose,
  header,
  headerTextPos,
  headerProps,
  closeBtn,
  modalProps,
  ...modalContentProps
}) => {
  const [visibility, setVisibility] = useState(false);
  useEffect(() => {
    isOpen ? setVisibility(true) : setVisibility(false);
  }, [isOpen]);
  if (!visibility) {
    return null;
  }
  return <Modal isOpen={visibility} onClose={onClose} {...modalProps} data-sentry-element="Modal" data-sentry-component="ModalComponent" data-sentry-source-file="Modal.tsx">
      <ModalOverlay bg="#0A2429" opacity="0.5 !important" data-sentry-element="ModalOverlay" data-sentry-source-file="Modal.tsx" />
      <ModalContent alignSelf="center" display="flex" alignItems="center" justifyContent="center" {...modalContentProps} data-sentry-element="ModalContent" data-sentry-source-file="Modal.tsx">
        {header && <ModalHeader {...headerProps}>
            {closeBtn && <ModalCloseButton onClick={onClose} />}
            <Text {...headerTextPos}>{header}</Text>
          </ModalHeader>}
        <ModalBody py={8} w="100%" data-sentry-element="ModalBody" data-sentry-source-file="Modal.tsx">
          {children}
        </ModalBody>
      </ModalContent>
    </Modal>;
};
export default ModalComponent;