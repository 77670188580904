import { useState } from 'react';
import { Flex, Box, Icon, Text, Button } from '@chakra-ui/react';
import React from 'react';
import NavLink from './nav-link';
import { MdOutlineLogout } from 'react-icons/md';
import { useAuth } from '../../contexts/AuthContext';
import Modal from '../Modal';
import { get_token } from '../../utils/CookieStore';
import { useRouter } from 'next/router';
import { BsPersonPlus } from 'react-icons/bs';
interface Auth {
  logout: () => void;
}
interface LinkProps {
  label: string;
  icon: string;
  url: string;
  link: string;
  size: string;
}
const links = {
  profile: {
    icon: 'Profile',
    url: '/account/profile',
    label: 'Profile',
    size: '1.5rem'
  }
};
const SecondaryMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const {
    logout
  } = useAuth() as Auth;
  const token = get_token();
  const router = useRouter();
  const handleLogout = () => {
    setIsOpen(true);
  };
  const navLinks: Array<object> = Object.values(links);
  return <Flex flexDirection="column" gap={7} my={6} w="100%" data-sentry-element="Flex" data-sentry-component="SecondaryMenu" data-sentry-source-file="secondary-nav.tsx">
      {token ? <>
          {navLinks.map((navLink: object, i: number) => {
        return <NavLink key={i} link={navLink as LinkProps} />;
      })}
          <Box display="flex" alignItems="center" my={8} style={{
        cursor: 'pointer'
      }} onClick={handleLogout}>
            <Box display="flex" mr="10px" bg="iconBg" color="icon" p="8px" borderRadius="8px" w="32px">
              <Icon as={MdOutlineLogout} boxSize="15px" mr="22px" />
            </Box>
            <Text fontSize="15px" lineHeight="18px" fontWeight="600" color="title">
              Log out
            </Text>
          </Box>
        </> : <>
          <Box display="flex" alignItems="center" mt={8} style={{
        cursor: 'pointer'
      }} onClick={() => router.push('/login')}>
            <Box display="flex" mr="10px" bg="iconBg" color="icon" p="8px" borderRadius="8px" w="32px">
              <Icon as={MdOutlineLogout} boxSize="15px" mr="22px" />
            </Box>
            <Text fontSize="15px" lineHeight="18px" fontWeight="600" color="title">
              Log in
            </Text>
          </Box>
          <Box display="flex" alignItems="center" style={{
        cursor: 'pointer'
      }} onClick={() => router.push('/register')}>
            <Box display="flex" mr="10px" bg="iconBg" color="icon" p="8px" borderRadius="8px" w="32px">
              <Icon as={BsPersonPlus} boxSize="15px" mr="22px" />
            </Box>
            <Text fontSize="15px" lineHeight="18px" fontWeight="600" color="title">
              Register
            </Text>
          </Box>
        </>}
      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} header="Are you sure you want to logout?" closeBtn={true} py={8} width={{
      base: '95%',
      md: '50%'
    }} data-sentry-element="Modal" data-sentry-source-file="secondary-nav.tsx">
        <Flex flexDirection={{
        md: 'column'
      }} gap={4} alignItems="center" data-sentry-element="Flex" data-sentry-source-file="secondary-nav.tsx">
          <Button bg="#0F61D6" color="#fff" w="80%" _hover={{
          bg: '#0F61D6',
          opacity: '0.9'
        }} onClick={() => {
          setIsOpen(false);
          logout();
        }} data-sentry-element="Button" data-sentry-source-file="secondary-nav.tsx">
            Yes
          </Button>
          <Button boxShadow="0px 1px 2px rgba(16, 24, 40, 0.05)" borderColor="#D0D5DD" bg="white" w="80%" onClick={() => setIsOpen(false)} data-sentry-element="Button" data-sentry-source-file="secondary-nav.tsx">
            No
          </Button>
        </Flex>
      </Modal>
    </Flex>;
};
export default SecondaryMenu;